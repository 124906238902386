import config from 'configs/app';
import { getEnvValue } from 'configs/app/utils';

// FIXME
// I was not able to figure out how to send CORS with credentials from localhost
// unsuccessfully tried different ways, even custom local dev domain
// so for local development we have to use next.js api as proxy server
export default function isNeedProxy() {
  return true;

  // if (config.app.useProxy) {
  //   return true;
  // }

  // config.app.host -> NEXT_PUBLIC_APP_HOST -> localhost
  // config.api.host -> NEXT_PUBLIC_API_HOST -> scanner-backend-otg-dev.gunztoken.cc

  return config.app.host === 'localhost' && config.app.host !== config.api.host;
}
