import { Flex, useColorModeValue, Box, Image, Link } from '@chakra-ui/react';
import React from 'react';

import { CONTENT_MAX_WIDTH } from 'ui/shared/layout/utils';
import SearchBar from 'ui/snippets/searchBar/SearchBar';

// import TopBarStats from './TopBarStats';

const TopBar = () => {
  // const bgColor = useColorModeValue('gray.50', 'whiteAlpha.100');

  return (
    <Box
      bgColor="black"
      position="relative"
      zIndex={2}
      borderBottom="1px solid"
      borderColor="divider"
    >
      <Flex
        py={1}
        px={{ base: 4, lg: 6 }}
        maxW={`${CONTENT_MAX_WIDTH}px`}
        m="0 auto"
        justifyContent="space-between"
        alignItems="center"
      >
        {/* <TopBarStats/> */}

        <Flex direction="row" alignItems="center">
          <Link href="/" mr={20}>
            <Image
              src="/static/gunz_logo.svg"
              alt="Gunz logo"
              boxSize={16}
              mr={2}
            />
          </Link>

          <nav>
            <Flex direction="row" alignItems="center">
              <Link color="white" href="/txs" mr={6}>
                Transactions
              </Link>
              <Link color="white" href="/blocks" mr={6}>
                Blocks
              </Link>
              <Link color="white" href="/tokens">
                Tokens
              </Link>
            </Flex>
          </nav>
        </Flex>

        <Flex alignItems="center" width="30%">
          <SearchBar isHomepage width="100%" />
        </Flex>
      </Flex>
    </Box>
  );
};

export default React.memo(TopBar);
